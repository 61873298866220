<template>
	<div style="padding: 30px 0;">
		<div class="tkcontent">
			<div class="title">{{ $t("header.YSZC") }}</div>
			<div class="cont" v-html="content"></div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				content:''
			};
		},
		created(){
			this.getDatas();
		},
		methods:{
			async getDatas(){
				let res=await this.$request.get('/api/sysdict/getProtectionPolicy');
				this.content=res.data;
			}
		}
	};
</script>

<style lang="less">
	.tkcontent{width: 1000px;min-height: calc(100vh - 276px); background-color: #000;padding: 40px;box-sizing: border-box;margin:0 auto;color: #fff; line-height: 1.6;
		.title{text-align: center;font-size: 18px;border-bottom: 1px solid #567BB6;padding-bottom: 25px;}
		.cont{margin-top: 30px;
			p,h1,h2,h3,h4,h5,h6{ margin: revert;padding:revert ;line-height: initial; }
			strong,h1,h2,h3,h4,h5,h6{font: revert;}
		}	
	}
</style>